import React from 'react';
import { motion } from 'framer-motion';
import { FaHeart } from 'react-icons/fa';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative bg-gradient-to-br from-[#3c2a5e] via-[#6b3e5e] to-[#3c2a5e] text-white py-8 overflow-hidden">
      <div className="container mx-auto px-4 text-center relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <p className="text-sm sm:text-base mb-2">
            &copy; {currentYear} Vaishnavi Educare. All rights reserved.
          </p>
          <p className="text-xs sm:text-sm text-[#e87c7c] mb-4">
            Empowering medical aspirants with expert guidance and support for over a decade.
          </p>
          <motion.div
            className="inline-flex items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <span className="mr-2">Made with</span>
            <motion.div
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ repeat: Infinity, duration: 1.5 }}
            >
              <FaHeart className="text-[#e87c7c]" />
            </motion.div>
            <span className="ml-2">in India</span>
          </motion.div>
        </motion.div>
      </div>
      <div className="absolute inset-0 opacity-20 mix-blend-screen">
        <div className="w-full h-full bg-gradient-to-t from-[#e87c7c] to-transparent" />
      </div>
    </footer>
  );
};

export default Footer;
import React, { useEffect, useRef } from 'react';

const AnimatedBackdrop = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const createRandomShape = () => {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const size = Math.random() * 60 + 20;
      const opacity = Math.random() * 0.2 + 0.05;
      const speed = Math.random() * 0.3 + 0.1;
      const direction = Math.random() * Math.PI * 2;
      const type = Math.random();
      const roundness = Math.random() * 0.5 + 0.5;
      return { x, y, size, opacity, speed, direction, type, roundness };
    };

    const shapes = Array(30).fill().map(createRandomShape);

    const drawShape = (shape) => {
      ctx.beginPath();
      ctx.globalAlpha = shape.opacity;

      if (shape.type < 0.33) {
        // Circle
        ctx.arc(shape.x, shape.y, shape.size / 2, 0, Math.PI * 2);
      } else if (shape.type < 0.66) {
        // Capsule
        const capsuleHeight = shape.size * 1.5;
        const radius = shape.size / 2;
        ctx.moveTo(shape.x, shape.y - capsuleHeight / 2 + radius);
        ctx.arcTo(shape.x + radius, shape.y - capsuleHeight / 2, shape.x + radius, shape.y, radius);
        ctx.arcTo(shape.x + radius, shape.y + capsuleHeight / 2, shape.x, shape.y + capsuleHeight / 2, radius);
        ctx.arcTo(shape.x - radius, shape.y + capsuleHeight / 2, shape.x - radius, shape.y, radius);
        ctx.arcTo(shape.x - radius, shape.y - capsuleHeight / 2, shape.x, shape.y - capsuleHeight / 2, radius);
      } else {
        // Smooth triangle
        const h = shape.size * Math.sqrt(3) / 2;
        ctx.moveTo(shape.x, shape.y - h / 2);
        ctx.quadraticCurveTo(shape.x + shape.size / 2, shape.y + h / 2, shape.x, shape.y + h / 2);
        ctx.quadraticCurveTo(shape.x - shape.size / 2, shape.y + h / 2, shape.x, shape.y - h / 2);
      }

      ctx.fillStyle = 'white';
      ctx.fill();
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      shapes.forEach(shape => {
        shape.x += Math.cos(shape.direction) * shape.speed;
        shape.y += Math.sin(shape.direction) * shape.speed;

        if (shape.x < -shape.size) shape.x = canvas.width + shape.size;
        if (shape.x > canvas.width + shape.size) shape.x = -shape.size;
        if (shape.y < -shape.size) shape.y = canvas.height + shape.size;
        if (shape.y > canvas.height + shape.size) shape.y = -shape.size;

        drawShape(shape);
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="absolute inset-0 opacity-20"
      style={{ mixBlendMode: 'screen' }}
    />
  );
};

export default AnimatedBackdrop;
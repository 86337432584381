import React, { useState, useCallback, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import AnimatedBackdrop from './AnimatedBackdrop';

const About = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const [counters, setCounters] = useState({ years: 0, admissions: 0, colleges: 0 });

  const animateCounters = useCallback(() => {
    const duration = 2000;
    const steps = 60;
    const stepDuration = duration / steps;

    const targetValues = { years: 12, admissions: 1000, colleges: 300 };
    const stepSizes = {
      admissions: targetValues.admissions / steps,
      colleges: targetValues.colleges / steps,
    };

    let currentStep = 0;

    const intervalId = setInterval(() => {
      if (currentStep < steps) {
        setCounters(prev => ({
          years: Math.min(prev.years + 1, targetValues.years),
          admissions: Math.min(Math.round(prev.admissions + stepSizes.admissions), targetValues.admissions),
          colleges: Math.min(Math.round(prev.colleges + stepSizes.colleges), targetValues.colleges),
        }));
        currentStep++;
      } else {
        clearInterval(intervalId);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      animateCounters();
    }
  }, [controls, inView, animateCounters]);

  const aboutText1 = "Vaishnavi Educare is a leading career guidance organization dedicated to guiding students in Medical, Engineering, Law, and Management fields. With over a decade of experience, we've successfully helped thousands of students achieve their dreams.";
  const aboutText2 = "Our mission is to provide comprehensive information about educational opportunities in various streams across India and abroad, creating awareness about the impact of higher education in a globalized society.";

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  return (
    <section id="about" className="relative min-h-screen flex items-center justify-center bg-gradient-to-br from-[#3c2a5e] via-[#6b3e5e] to-[#3c2a5e] py-16 overflow-hidden" ref={ref}>
      <AnimatedBackdrop />
      <motion.div
        className="container mx-auto px-4 text-center relative z-10"
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
        }}
      >
        <h2 className="text-3xl sm:text-4xl font-bold mb-8 text-white">About Vaishnavi Educare</h2>
        <motion.p
          className="text-lg mb-6 text-gray-300"
          variants={textVariants}
          initial="hidden"
          animate={controls}
        >
          {aboutText1}
        </motion.p>
        <motion.p
          className="text-lg mb-6 text-gray-300"
          variants={textVariants}
          initial="hidden"
          animate={controls}
        >
          {aboutText2}
        </motion.p>
        <div className="grid grid-cols-3 gap-2 sm:gap-4 md:gap-8 mt-6 sm:mt-12">
          <div className="bg-[#e87c7c] bg-opacity-10 p-2 sm:p-4 md:p-6 rounded-lg shadow-md backdrop-filter backdrop-blur-sm">
            <h3 className="text-xl sm:text-2xl md:text-3xl font-bold mb-1 sm:mb-2 text-[#e87c7c]">{counters.years}+</h3>
            <p className="text-white text-xs sm:text-sm md:text-base">Years of Experience</p>
          </div>
          <div className="bg-[#e87c7c] bg-opacity-10 p-2 sm:p-4 md:p-6 rounded-lg shadow-md backdrop-filter backdrop-blur-sm">
            <h3 className="text-xl sm:text-2xl md:text-3xl font-bold mb-1 sm:mb-2 text-[#e87c7c]">{counters.admissions}+</h3>
            <p className="text-white text-xs sm:text-sm md:text-base">Successful Admissions</p>
          </div>
          <div className="bg-[#e87c7c] bg-opacity-10 p-2 sm:p-4 md:p-6 rounded-lg shadow-md backdrop-filter backdrop-blur-sm">
            <h3 className="text-xl sm:text-2xl md:text-3xl font-bold mb-1 sm:mb-2 text-[#e87c7c]">{counters.colleges}+</h3>
            <p className="text-white text-xs sm:text-sm md:text-base">Partner Colleges</p>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default About;
import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaGraduationCap, FaUniversity, FaUserMd, FaHandshake, FaChartLine, FaGlobe } from 'react-icons/fa';
import AnimatedBackdrop from './AnimatedBackdrop';

const Services = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const services = [
    {
      title: "Medical Admissions",
      description: "Expert guidance for MBBS, BAMS, BHMS, and BDS admissions with packages starting from ₹9.5 Lakhs.",
      icon: <FaUserMd className="text-4xl text-[#ed6a6a]" />
    },
    {
      title: "Extensive College Network",
      description: "Access to 300+ colleges across India for diverse options in medical education.",
      icon: <FaUniversity className="text-4xl text-[#ed6a6a]" />
    },
    {
      title: "Experienced Counselling",
      description: "10+ years of experience with over 1000+ successful admissions.",
      icon: <FaGraduationCap className="text-4xl text-[#ed6a6a]" />
    },
    {
      title: "Personalized Support",
      description: "Tailored assistance for students with varying NEET scores and budgets.",
      icon: <FaHandshake className="text-4xl text-[#ed6a6a]" />
    },
    {
      title: "Career Guidance",
      description: "Comprehensive career counselling for Medical, Engineering, Law, and Management fields.",
      icon: <FaChartLine className="text-4xl text-[#ed6a6a]" />
    },
    {
      title: "Online Counselling",
      description: "Convenient online counselling services for all courses, including choice filling assistance.",
      icon: <FaGlobe className="text-4xl text-[#ed6a6a]" />
    }
  ];

  return (
    <section id="services" className="relative min-h-screen flex items-center justify-center bg-gradient-to-br from-[#3c2a5e] via-[#6b3e5e] to-[#3c2a5e] py-16 overflow-hidden" ref={ref}>
      <AnimatedBackdrop />
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-12 text-center text-white"
          initial={{ opacity: 0, y: -50 }}
          animate={controls}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
          }}
        >
          Our Services
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="bg-[#ed6a6a] bg-opacity-10 p-6 rounded-lg shadow-md backdrop-filter backdrop-blur-sm flex flex-col items-center"
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: index * 0.1 } },
              }}
            >
              <div className="flex items-center justify-center mb-4 w-16 h-16 bg-[#e08a8a] bg-opacity-20 rounded-full">
                {service.icon}
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white text-center">{service.title}</h3>
              <p className="text-gray-300 text-center">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaGlobeAmericas, FaGraduationCap } from 'react-icons/fa';
import AnimatedBackdrop from './AnimatedBackdrop';

const InternationalPrograms = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { 
        duration: 0.8,
        staggerChildren: 0.2
      } 
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5 } 
    },
  };

  return (
    <section id="international" className="relative min-h-screen flex items-center justify-center bg-gradient-to-br from-[#3c2a5e] via-[#6b3e5e] to-[#3c2a5e] py-16 overflow-hidden" ref={ref}>
      <AnimatedBackdrop />
      <motion.div
        className="container mx-auto px-4 relative z-10"
        initial="hidden"
        animate={controls}
        variants={containerVariants}
      >
        <h2 className="text-3xl sm:text-4xl font-bold mb-12 text-center text-white">International Opportunities</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.div className="bg-[#e87c7c] bg-opacity-10 p-6 rounded-lg shadow-md backdrop-filter backdrop-blur-sm" variants={itemVariants}>
            <div className="flex items-center mb-4">
              <FaGlobeAmericas className="text-3xl text-[#e87c7c] mr-4" />
              <h3 className="text-2xl font-semibold text-white">MBBS in Russia</h3>
            </div>
            <p className="text-gray-300 mb-4">
              Complete your MBBS degree in Russia for just 22 lakhs. Affordable tuition fees starting at ₹1,00,000 per semester.
            </p>
            <ul className="list-disc list-inside text-gray-300">
              <li>Includes tuition, hostel, and meals</li>
              <li>Recognized degrees</li>
              <li>Excellent learning environment</li>
            </ul>
          </motion.div>
          <motion.div className="bg-[#e87c7c] bg-opacity-10 p-6 rounded-lg shadow-md backdrop-filter backdrop-blur-sm" variants={itemVariants}>
            <div className="flex items-center mb-4">
              <FaGraduationCap className="text-3xl text-[#e87c7c] mr-4" />
              <h3 className="text-2xl font-semibold text-white">5-Year MBBS/MD Program</h3>
            </div>
            <p className="text-gray-300 mb-4">
              Enroll in a comprehensive 5-year MBBS/MD program at Desh Bhagat University's Americas School of Medicine.
            </p>
            <ul className="list-disc list-inside text-gray-300">
              <li>Designed for aspiring doctors in the USA</li>
              <li>Comprehensive curriculum</li>
              <li>Practical training opportunities</li>
            </ul>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default InternationalPrograms;
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import AnimatedBackdrop from './AnimatedBackdrop';

const servicePoints = [
  { title: 'Enquiry', content: 'Visit our website official Enquiry number and our office to explain your needs in details.' },
  { title: 'Career Counselling', content: 'Get most suitable career options for your future, Choose your desired course, stream and colleges.' },
  { title: 'Online Counselling', content: 'We provide online counselling Services for all Courses Like Medical, Engineering, Management, Law and all other courses.' },
  { title: 'Choice Filling', content: 'We provide Online Choice filling of college in State Counselling And Central AIQ Counselling for all Courses.' },
  { title: 'Value of Money', content: 'We realize the value of money and hence are cost effective. We diligently guide the students through every step of the admission procedure.' },
  { title: 'Get Admission', content: 'Get easy Admission with Assistance of Team Vaishnavi Educare . Relax! What we commit, We do It.' },
];

const Hero = () => {
  const [hoveredPoint, setHoveredPoint] = useState(null);

  return (
    <section className="relative min-h-screen flex items-center justify-center px-4 bg-gradient-to-br from-[#3c2a5e] via-[#6b3e5e] to-[#3c2a5e] overflow-hidden">
      <AnimatedBackdrop />
      {/* Content */}
      <div className="relative z-10 max-w-7xl w-full flex flex-col md:flex-row items-center justify-between">
        {/* Left column */}
        <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
          <motion.div
            className="mb-8"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <img src="/logo.png" alt="Vaishnavi Educare Logo" className="mx-auto md:mx-0 w-64 h-auto" />
          </motion.div>
          <motion.h1
            className="text-4xl sm:text-5xl md:text-6xl font-bold mb-2 text-white"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Vaishnavi Educare
          </motion.h1>
          <motion.p
            className="text-xl sm:text-2xl md:text-3xl mb-6 text-[#e87c7c]"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            Education Consultancy
          </motion.p>
          <motion.p
            className="text-lg sm:text-xl mb-8 text-[#d1b3d1]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            Your Trusted Guide to Professional Education<br />
            Empowering futures in Medical, Engineering, Law, and Management for over a decade
          </motion.p>
          <motion.div
            className="inline-block"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="#about"
              className="bg-[#e45858] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#e34d4d] hover:text-[#3c2a5e] transition duration-300 shadow-lg"
            >
              Learn More
            </a>
          </motion.div>
        </div>

        {/* Right column */}
        <div className="w-full md:w-1/2 grid grid-cols-2 md:grid-cols-1 gap-3 md:gap-3 md:pl-20 md:pt-20 pl-2 pt-2">
          {servicePoints.map((point, index) => (
            <motion.div
              key={point.title}
              className="cursor-pointer mb-4 md:mb-6"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 * index }}
              onHoverStart={() => setHoveredPoint(index)}
              onHoverEnd={() => setHoveredPoint(null)}
            >
              <div className="flex items-center text-white">
                <motion.span
                  className="mr-3 text-green-400 text-xl md:text-3xl"
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3, delay: 0.2 * index + 0.2 }}
                >
                  ✓
                </motion.span>
                <h3 className="text-sm md:text-xl font-semibold">{point.title}</h3>
              </div>
              <AnimatePresence>
                {hoveredPoint === index && (
                  <motion.p
                    className="mt-3 ml-8 text-xs md:text-base text-[#d1b3d1]"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    {point.content}
                  </motion.p>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;
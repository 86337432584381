import { useRef, useState, useEffect } from 'react';
const ScrollContainer = ({ children }) => {
    const containerRef = useRef(null);
    const [currentSection, setCurrentSection] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
  
    useEffect(() => {
      const container = containerRef.current;
  
      const handleWheel = (e) => {
        e.preventDefault();
        if (isScrolling) return;
  
        const direction = e.deltaY > 0 ? 1 : -1;
        const newSection = Math.max(0, Math.min(children.length - 1, currentSection + direction));
  
        if (newSection !== currentSection) {
          setIsScrolling(true);
          setCurrentSection(newSection);
          container.children[newSection].scrollIntoView({ behavior: 'smooth' });
  
          setTimeout(() => {
            setIsScrolling(false);
          }, 1000);
        }
      };
  
      container.addEventListener('wheel', handleWheel, { passive: false });
  
      return () => {
        container.removeEventListener('wheel', handleWheel);
      };
    }, [children.length, currentSection, isScrolling]);
  
    return (
      <div ref={containerRef} className="h-screen overflow-y-auto md:overflow-hidden">
        {children}
      </div>
    );
  };

export default ScrollContainer;
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaBars } from 'react-icons/fa';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [logoGlow, setLogoGlow] = useState(0);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  useEffect(() => {
    const glowInterval = setInterval(() => {
      setLogoGlow((prev) => (prev === 0 ? 1 : 0));
    }, 3000);

    return () => clearInterval(glowInterval);
  }, []);

  const NavLink = ({ href, children }) => (
    <motion.a
      href={href}
      className="px-3 py-2 text-lg font-medium text-white hover:text-[#e87c7c] transition duration-300"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => setIsMenuOpen(false)}
    >
      {children}
    </motion.a>
  );

  return (
    <motion.nav
      className={`fixed top-0 left-0 right-0 z-50 ${
        scrolled ? 'bg-[#3c2a5e] bg-opacity-90' : 'bg-transparent'
      } transition-all duration-300`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <motion.a
            href="/"
            className="flex items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="relative">
              <div className="absolute inset-0 bg-[#e87c7c] opacity-75 blur-md rounded-full"></div>
              <img 
                src="/logo.png" 
                alt="Vaishnavi Educare Logo" 
                className="h-10 w-auto relative z-10"
              />
            </div>
            <span className="hidden sm:inline text-2xl font-bold text-white ml-2">VaishnaviEducare</span>
            <span className="sm:hidden text-2xl font-bold text-white ml-2">VE</span>
          </motion.a>
          <div className="hidden md:flex space-x-4">
            {['Home', 'Services', 'About', 'Contact'].map((item) => (
              <NavLink key={item} href={item === 'Home' ? '/' : `#${item.toLowerCase()}`}>
                {item}
              </NavLink>
            ))}
          </div>
          <div className="md:hidden">
            <motion.button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </motion.button>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className="md:hidden bg-[#3c2a5e] bg-opacity-95"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="px-2 pt-2 pb-3 space-y-1">
              {['Home', 'Services', 'About', 'Contact'].map((item) => (
                <NavLink key={item} href={item === 'Home' ? '/' : `#${item.toLowerCase()}`}>
                  {item}
                </NavLink>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

export default Navbar;
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaPhoneAlt, FaGlobe, FaMapMarkerAlt } from 'react-icons/fa';
import AnimatedBackdrop from './AnimatedBackdrop';

const Contact = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { 
        duration: 0.8,
        staggerChildren: 0.2
      } 
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5 } 
    },
  };

  const iconVariants = {
    hidden: { scale: 0 },
    visible: { 
      scale: 1, 
      transition: { 
        type: "spring",
        stiffness: 260,
        damping: 20 
      } 
    },
  };

  return (
    <section id="contact" className="relative min-h-screen flex items-center justify-center bg-gradient-to-br from-[#3c2a5e] via-[#6b3e5e] to-[#3c2a5e] py-16 overflow-hidden" ref={ref}>
      <AnimatedBackdrop />
      <motion.div
        className="container mx-auto px-4 text-center relative z-10"
        initial="hidden"
        animate={controls}
        variants={containerVariants}
      >
        <h2 className="text-3xl sm:text-4xl font-bold mb-12 text-white">Contact Us</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
          <motion.div 
            className="bg-[#e87c7c] bg-opacity-10 p-6 rounded-lg shadow-md backdrop-filter backdrop-blur-sm"
            variants={itemVariants}
          >
            <motion.div variants={iconVariants}>
              <FaPhoneAlt className="text-4xl mb-4 mx-auto text-[#e87c7c]" />
            </motion.div>
            <a href="tel:+917428404646" className="text-white hover:text-[#e87c7c] transition-colors duration-300 block">+91 7428404646</a>
            <a href="tel:+918368599479" className="text-white hover:text-[#e87c7c] transition-colors duration-300 block mt-2">+91 8368599479</a>
          </motion.div>
          <motion.div 
            className="bg-[#e87c7c] bg-opacity-10 p-6 rounded-lg shadow-md backdrop-filter backdrop-blur-sm"
            variants={itemVariants}
          >
            <motion.div variants={iconVariants}>
              <FaGlobe className="text-4xl mb-4 mx-auto text-[#e87c7c]" />
            </motion.div>
            <a href="https://www.vaishnavieducare.com" className="text-white hover:text-[#e87c7c] transition-colors duration-300">www.vaishnavieducare.com</a>
          </motion.div>
          <motion.div 
            className="bg-[#e87c7c] bg-opacity-10 p-6 rounded-lg shadow-md backdrop-filter backdrop-blur-sm"
            variants={itemVariants}
          >
            <motion.div variants={iconVariants}>
              <FaMapMarkerAlt className="text-4xl mb-4 mx-auto text-[#e87c7c]" />
            </motion.div>
            <p className="text-white">7/50 IInd Floor, Sector 11</p>
            <p className="text-white">Ghaziabad, Uttar Pradesh, India 201010</p>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default Contact;
import React from 'react';
import "./App.css";
import Navbar from './components/Navbar';
import ScrollContainer from './components/ScrollContainer';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import InternationalPrograms from './components/InternationalPrograms';
import Contact from './components/Contact';
import Footer from './components/Footer';
const App = () => {
  return (
    <div className="min-h-screen bg-white text-gray-800">
      <Navbar />
      <ScrollContainer>
        <Hero />
        <Services />
        <About />
        <InternationalPrograms />
        <Contact />
        <Footer />
      </ScrollContainer>
    </div>
  );
};




export default App;